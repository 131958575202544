import * as React from 'react'
import { Input } from '~/components/ui/input'
import { Search } from 'lucide-react'
import { Form, useParams, useSearchParams, useSubmit } from '@remix-run/react'
import { useDebounce, useIsPending } from '~/utils/misc.tsx'

function useIsMobile() {
	const [isMobile, setIsMobile] = React.useState(false)

	React.useEffect(() => {
		const mediaQuery = window.matchMedia('(max-width: 768px)') // You can adjust the width threshold

		// Update state based on the initial value
		setIsMobile(mediaQuery.matches)

		// Define a callback function to handle screen size changes
		const handleResize = (e: any) => {
			setIsMobile(e.matches)
		}

		// Add event listener for media query change
		mediaQuery.addEventListener('change', handleResize)

		// Cleanup function to remove event listener
		return () => {
			mediaQuery.removeEventListener('change', handleResize)
		}
	}, [])

	return isMobile
}

export function SearchDialog({
	status,
	autoFocus = false,
	autoSubmit = false,
}: {
	status: 'idle' | 'pending' | 'success' | 'error'
	autoFocus?: boolean
	autoSubmit?: boolean
}) {
	const inputRef = React.useRef<HTMLInputElement | null>(null)
	const isMobile = useIsMobile()
	const [searchParams] = useSearchParams()
	const submit = useSubmit()
	// TODO see if we want to add a spinner for this
	const isSubmitting = useIsPending({
		formMethod: 'GET',
		formAction: '/',
	})

	const handleFormChange = useDebounce((form: HTMLFormElement) => {
		submit(form)
	}, 400)

	React.useEffect(() => {
		const down = (e: KeyboardEvent) => {
			if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
				e.preventDefault()
				if (inputRef.current) {
					inputRef.current.focus()
				}
			}
		}

		document.addEventListener('keydown', down)
		return () => document.removeEventListener('keydown', down)
	}, [])

	return (
		<Form
			id="search-form"
			className="pb-4 pt-2 md:pb-2"
			method="GET"
			action="/"
			onChange={(e) => autoSubmit && handleFormChange(e.currentTarget)}
		>
			<div className="relative flex w-full flex-col items-center justify-center">
				<div className="relative flex-1 md:grow-0">
					<Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
					<Input
						ref={inputRef}
						name="search"
						type="search"
						autoFocus={autoFocus}
						defaultValue={searchParams.get('search') ?? ''}
						placeholder="Search..."
						className="w-[398px] rounded-lg bg-white pl-8 md:w-[800px] md:max-w-lg"
					/>
					{!isMobile && (
						<kbd className="pointer-events-none absolute right-2.5 top-2 inline-flex h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 font-mono text-[10px] font-medium text-muted-foreground opacity-100">
							<span className="text-xs">⌘</span>K
						</kbd>
					)}
				</div>
			</div>
		</Form>
	)
}
